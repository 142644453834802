.c-section-heading,
.c-section-heading__subtitle {
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include bp-down(medium) {
    max-width: 90%;
  }

  @include bp(medium) {
    max-width: 66%;
  }

  .c-testimonials & {
    margin-left: 0;
    margin-right: 0;
    text-align: left;
    max-width: none;
  }
}

.c-section-heading--heading-2 {
  @extend %heading-2;
}

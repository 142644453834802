.c-super {
  padding: var(--spacer-sections) 0;
}

.c-super--orange {
  color: $color-white;
}

.c-super__text {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
}

.c-super__picture {
  img {
    display: block;
    margin: 0 auto;
  }
}

.c-super__picture-wrapper {
  margin-left: calc(-1 * calc(var(--wrapper-hspace) - 10px));
  margin-right: calc(-1 * calc(var(--wrapper-hspace) - 10px));
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: $color-white $color-orange;

  &::-webkit-scrollbar {
    background-color: $color-orange;
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-white;
    border-radius: 5px;
  }
}

.c-super__text + .c-super__picture-wrapper,
.c-super__picture-wrapper + .c-super__text {
  margin-top: var(--spacer-medium);
}

.c-super .c-section-heading {
  @extend %heading-1;
}

.c-super .c-section-heading,
.c-super .c-section-heading__subtitle {
  text-align: center;
}

.c-super .o-wrapper > .c-btn {
  @extend %section-btn;

  margin-top: var(--spacer-medium);
}

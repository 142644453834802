$color-themes: (
  'dark': $color-black,
  'light': $color-light-grey,
  'orange': $color-orange,
  'purple': $color-purple,
  'green': $color-green,
);

@mixin bg-orange {
  background-color: $color-orange;
}

@mixin bg-purple {
  background-color: $color-purple;
}

@mixin bg-green {
  background-color: $color-green;
}

@mixin bg-light {
  background-color: $color-light-grey;
}

@mixin bg-dark {
  color: $color-light-grey;
  background-color: $color-black;
}

section[class*='--dark'] {
  @include bg-dark;
}

section[class*='--orange'] {
  @include bg-orange;
}

section[class*='--purple'] {
  @include bg-purple;
}

section[class*='--green'] {
  @include bg-green;
}

section[class*='--light'] {
  @include bg-light;
}

.c-neon:has(+ [class*='--skew-left']),
section:has(+ [class*='--skew-left']) {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - var(--skew-size)));
}

.c-neon:has(+ [class*='--skew-right']),
section:has(+ [class*='--skew-right']) {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--skew-size)), 0 100%);
}

.c-neon:has(+ [class*='--skew-left']),
.c-neon:has(+ [class*='--skew-right']) {
  padding-bottom: calc(var(--skew-size) + var(--neon-height));
}

section[class*='--skew-left'] {
  clip-path: polygon(0 0, 100% var(--skew-size), 100% 100%, 0 100%);
  margin-top: calc(-1 * var(--skew-size));
}

section[class*='--skew-right'] {
  clip-path: polygon(0 var(--skew-size), 100% 0, 100% 100%, 0 100%);
  margin-top: calc(-1 * var(--skew-size));
}

.c-team {
  padding: var(--spacer-sections) 0;
}

.c-team .c-section-heading {
  text-align: center;

  @extend %heading-1;
}

.c-team__name {
  margin-bottom: 0;
  font-family: $font-sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.45;
}

.c-team__slide {
  margin-bottom: 0;
}

.c-team__picture {
  margin-bottom: var(--spacer-small);
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1;
  width: 160px;
  display: block;

  @supports not (aspect-ratio: auto) {
    position: relative;
    padding-top: 100%;
    height: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    @supports not (aspect-ratio: auto) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.c-team__slider {
  margin-top: var(--spacer-medium);
}

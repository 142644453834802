/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */

/**
 * The list-inline object simply displays a list of items in one line.
 */

.o-list-arrows {
  list-style: none;
  margin-left: 42px;
}

.o-list-arrows li {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid $color-black;

  &:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &::before {
    display: inline-block;
    width: 23px;
    height: 15px;
    margin-right: 20px;
    content: '';
    position: relative;
    margin-left: -42px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjY1NyAxNC41NjVjLjQzLTMuMDEzIDIuMjctNS41MyA0LjY2MS02LjIyNkgwVjYuNjYxaDE5LjMxOGMtMi4zOS0uNjk1LTQuMjMyLTMuMjEzLTQuNjYxLTYuMjI2TDE2LjcxMiAwQzE2Ljk5IDQuMiAxOS43MTcgNi4zNzEgMjMgNi42NjF2MS42NzhjLTMuMjgzLjI5Mi02LjAxMSAyLjQ2My02LjI4OCA2LjY2MWwtMi4wNTUtLjQzNVoiIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=');
  }

  section[class*='--orange'] &,
  section[class*='--dark'] & {
    border-bottom-color: $color-white;
  }

  section[class*='--orange'] &::before,
  section[class*='--dark'] &::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjY1NyAxNC41NjVjLjQzLTMuMDEzIDIuMjctNS41MyA0LjY2MS02LjIyNkgwVjYuNjYxaDE5LjMxOGMtMi4zOS0uNjk1LTQuMjMyLTMuMjEzLTQuNjYxLTYuMjI2TDE2LjcxMiAwQzE2Ljk5IDQuMiAxOS43MTcgNi4zNzEgMjMgNi42NjF2MS42NzhjLTMuMjgzLjI5Mi02LjAxMSAyLjQ2My02LjI4OCA2LjY2MWwtMi4wNTUtLjQzNVoiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=');
  }
}

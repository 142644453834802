.c-infobar {
  padding: $spacer-small 0;
  font-size: 16px;
  line-height: 19px;
  color: $color-black;
  text-align: center;

  @include bg-purple;

  a {
    text-decoration: underline;
  }

  a,
  a:hover {
    color: $color-black;
  }

  a:hover {
    text-decoration: none;
  }

  .page-template-template-flexible-content &,
  .blog &,
  .archive:not(.woocommerce) & {
    @include bg-light;
  }

  .woocommerce-page:not(.woocommerce-account) & {
    display: none;
  }
}

.c-infobar--pulse {
  position: relative;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-purple;
    z-index: -1;
    box-shadow: 0 0 0 3px $color-green;
    animation: pulseBar 4s infinite;
  }
}

@keyframes pulseBar {
  0% {
    background-color: $color-green;
    box-shadow: 0 0 0 3px inherit;
  }

  40% {
    background-color: $color-orange;
  }

  60% {
    box-shadow: 0 0 0 3px inherit;
  }

  70% {
    background-color: $color-purple;
    box-shadow: 0 0 0 8px transparent;
  }

  100% {
    background-color: $color-green;
    box-shadow: 0 0 0 3px inherit;
  }
}

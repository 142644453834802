.c-pagination,
.woocommerce-pagination {
  padding-bottom: var(--spacer-sections);

  a {
    color: $color-black;
  }
}

.c-pagination .page-number,
.woocommerce-pagination.woocommerce-pagination li > .page-numbers {
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  font-family: $font-sans-serif;
  font-weight: 700;
  color: $color-white;
  background: $color-black;
  text-decoration: none;
  transition: $global-transition;

  @supports not (aspect-ratio: auto) {
    height: 30px;
  }

  &:hover,
  &.current {
    color: $color-white;
    background: $color-orange;
  }
}
/* stylelint-disable */
.woocommerce-pagination,
.woocommerce nav.woocommerce-pagination.woocommerce-pagination ul,
.c-pagination,
.c-pagination__list {
  display: flex;
  justify-content: center;
  gap: var(--spacer-small);
}

.woocommerce-pagination > .page-numbers,
.c-pagination__list {
  list-style: none inside;
}

.woocommerce nav.woocommerce-pagination.woocommerce-pagination ul,
.woocommerce nav.woocommerce-pagination.woocommerce-pagination ul li {
  border: none;
  margin: 0;
}

/* stylelint-enable */

.c-thankyou {
  padding-bottom: var(--spacer-sections);

  @include bp(large) {
    padding-top: var(--spacer-small);
  }

  .woocommerce-notice {
    @extend %heading-2;

    margin-bottom: var(--spacer-medium);
    text-align: center;
  }

  .woocommerce-notice--success::after {
    content: '🚀🔥';
    display: inline-block;
  }

  a:not(.c-btn) {
    color: $color-orange;
  }

  .product-purchase-note {
    font-size: 18px;
  }
}

.c-thankyou__footer {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;

  @include bp-down(medium) {
    flex-direction: column;
  }
}

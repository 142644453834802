.o-posts-listing {
  display: grid;
  padding-bottom: var(--spacer-sections);

  @include bp(medium) {
    grid-template-columns: 1fr 1fr;
    gap: 60px 40px;
  }

  @include bp-down(medium) {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .c-tease-post__picture {
    border-radius: 10px;
  }
}

.c-ft-posts .o-posts-listing {
  padding-bottom: 0;
}

.c-email {
  display: inline-block;
  font-family: $font-serif;
  line-height: 1.21;
  font-size: inherit;
}

.c-footer .c-email {
  display: block;

  @include bp(medium) {
    font-size: 32px;
  }

  @include bp-down(medium) {
    font-size: 28px;
  }
}

.c-email__link {
  position: relative;
  display: flex;
  color: $color-orange;
  transition: $global-transition;

  &:hover {
    color: $color-orange;
  }

  .o-icon {
    @include bp(medium) {
      margin-left: var(--spacer-xsmall);
    }

    @include bp-down(medium) {
      margin-left: calc(var(--spacer-xsmall) / 2);
    }
  }
}

.c-footer .c-email__link {
  color: $color-purple;

  @include bp-down(medium) {
    justify-content: center;
  }
}

.js-copy-value {
  position: relative;
}

.copied::after {
  content: 'Skopiowano do schowka';
  display: inline-block;
  position: absolute;
  font-family: $font-sans-serif;
  font-size: 0.5em;
  letter-spacing: 1px;
  padding: 1px;
  top: 100%;
  line-height: 1;
  color: $color-white;

  @include bg-dark;
}

.c-ft-content {
  padding: var(--spacer-sections) 0;
  text-align: center;
}

.c-ft-content .c-section-heading {
  @extend %heading-1;
}

.c-ft-content__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 60px 40px;
  margin: var(--spacer-medium) 0;

  @include bp(medium) {
    grid-template-columns: 1fr 1fr;
  }

  @include bp-down(medium) {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.c-ft-content__item {
  text-align: left;
  width: 100%;
  overflow: hidden;
}

.c-ft-content__picture {
  display: block;
}

.c-ft-content__item > .c-ft-content__picture,
.c-ft-content__slider,
.c-ft-content__video {
  margin-bottom: var(--spacer-small);
}

.c-ft-content__image {
  display: block;
  border-radius: 10px;
}

.c-ft-content__slider .glide__arrows {
  position: absolute;
  bottom: 15px;
  right: 15px;

  @include bp-down(small) {
    bottom: 5px;
    right: 5px;
  }
}

.c-ft-content__slider .glide__arrow {
  color: $color-white;
  background-color: transparent;

  &:hover {
    background-color: $color-black;
  }
}

.c-ft-content__video {
  position: relative;
  width: 100%;
  aspect-ratio: 1280/720;

  @supports not (aspect-ratio: auto) {
    height: 0;
    padding-top: 56%;
  }
}

.c-ft-content__video .c-embed {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.c-ft-content__video iframe {
  width: 100%;
  height: 100%;
}

.c-ft-content__item .c-btn {
  @extend %tile-btn;
}

.c-ft-content .o-wrapper > .c-btn {
  @extend %section-btn;
}

.c-tease-post {
  display: block;
  color: $color-black;
  text-decoration: none;
}

section[class*='--dark'] .c-tease--post {
  color: $color-white;
}

.c-tease-post__picture {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: var(--spacer-small);
  border-radius: 10px;

  .o-icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    font-size: 45px;
    transition: $global-transition;
  }
}

.c-tease-post__image {
  transition: $global-transition;
}

.c-tease-post:hover {
  .c-tease-post__image {
    transform: scale(1.1);
  }

  .o-icon {
    color: $color-white;
    background-color: $color-black;
  }
}

.c-tease__categories {
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
  flex-wrap: wrap;

  @include bp-down(medium) {
    gap: 5px;
  }

  @include bp-down(small) {
    gap: 3px;
  }
}

.c-tease__category-link {
  display: inline-block;
  font-size: 14px;

  &::after {
    content: ',';
  }

  &:last-child::after {
    content: none;
  }
}

.c-tease__title {
  @extend %heading-2;
}

.c-tease__category-link,
.c-tease__title a {
  color: $color-black;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: $global-transition;

  &:hover {
    border-bottom-color: $color-black;
  }

  section[class*='--dark'] & {
    color: $color-white;

    &:hover {
      border-bottom-color: $color-white;
    }
  }
}

.c-hero--delfiny .c-hero__grid {
  max-height: none;
  height: auto;
}

.c-hero--delfiny .c-hero__deco {
  @include bp(medium) {
    max-width: 570px;
  }

  @include bp-down(medium) {
    width: 50%;
    height: 100%;
    margin: -20px 0;
  }
}

.c-hero--delfiny .c-hero__content {
  @include bp-down(medium) {
    padding-top: var(--spacer-medium);
  }
}

.c-hero-deco--delfiny {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 107%;

  @include bp-down(medium) {
    // background-image: url('~assets/images/img-delfin-mobile.png');
    background-image: url('~assets/images/img-delfin.gif');
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.c-hero-deco--delfiny-lupa,
.c-hero-deco--delfiny-mozg,
.c-hero-deco--delfiny-reka {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-size: auto 70%;
  background-repeat: no-repeat;
  transform-origin: bottom;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.c-hero-deco--delfiny-mozg {
  bottom: 0;
  left: 0;

  @include bp(medium) {
    background-image: url('~assets/images/img-delfin-mozg.gif');
    background-position: left center;
    animation-name: slide-brain;
    animation-duration: 4s;
    animation-delay: 0s;
  }
}

.c-hero-deco--delfiny-lupa {
  right: 0;
  bottom: 0;

  @include bp(medium) {
    background-image: url('~assets/images/img-delfin-lupa.gif');
    background-position: right top;
    animation-name: slide-glass;
    animation-duration: 4s;
    animation-delay: 1s;
  }
}

.c-hero-deco--delfiny-reka {
  bottom: 0;
  right: 0;

  @include bp(medium) {
    background-image: url('~assets/images/img-delfin-reka.png');
    background-position: center 160%;
    animation-name: slide-hand;
    animation-duration: 3s;
    animation-delay: 0s;
  }
}

@keyframes slide-hand {
  0% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-glass {
  0% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(60px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-brain {
  0% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(80px);
  }

  100% {
    transform: translateY(0);
  }
}

.c-social-media {
  margin-bottom: 0;
}

.c-footer .c-social-media {
  @include bp-down(medium) {
    justify-content: center;
  }

  @include bp(medium) {
    margin-left: calc(-1 * var(--spacer-xsmall));
  }
}

.c-social-media__item {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.c-social-media__link {
  padding: 10px;
  display: inline-block;
  transition: $global-transition;
  color: $color-black;

  &:hover {
    color: $color-orange;
  }
}
/* stylelint-disable no-descending-specificity */
.page-template-template-flexible-content,
.blog,
.archive:not(.woocommerce) {
  .c-social-media__link:hover {
    color: $color-hover;
  }
}

.c-footer .c-social-media__link {
  color: $color-purple;

  &:hover {
    color: $color-orange;
  }
}
/* stylelint-enable no-descending-specificity */

.c-text-columns {
  padding: var(--spacer-sections) 0;

  &--linktree {
    padding: calc(var(--spacer-sections) / 2) 0;
  }

  .icp-countdown + p {
    margin-top: var(--spacer-small);
  }
}

/* stylelint-disable */
section[class*='--light'] + section[class*='--light'].c-text-columns--skew-none {
  padding-top: 0;
}/* stylelint-enable */

.c-text-columns--orange {
  color: $color-white;
}

.c-text-columns__grid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(12, 1fr);
  justify-content: space-between;

  @include bp(medium) {
    gap: 20px;
  }

  @include bp(xlarge) {
    gap: 40px;
  }

  @include bp-down(small) {
    gap: 40px 0;
  }
}

.c-text-columns__col {
  grid-column: span 12;

  > * {
    margin-bottom: var(--spacer-small);
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.c-text-columns__grid:not(.c-text-columns__grid--single) {
  .c-text-columns__col {
    @include bp(medium) {
      grid-column: span 6;
    }
  }
}

.c-text-columns__title {
  @extend %heading-1;

  text-align: center;

/* stylelint-disable */
  + * {
    margin-top: var(--spacer-medium);
  }
/* stylelint-enable */
}

.c-text-columns__grid.c-text-columns__grid--single {
  .c-text-columns__col {
    @include bp(medium) {
      grid-column: span 10;
      grid-column-start: 2;
      grid-column-end: 12;
    }

    @include bp(xlarge) {
      grid-column: span 8;
      grid-column-start: 3;
      grid-column-end: 11;
    }
  }

  *:not(.c-text-columns__picture) + .c-text-columns__title {
    margin-top: var(--spacer-medium);
  }
}

.c-text-columns__col .c-buttons {
  display: flex;
  justify-content: center;

  + .c-buttons {
    margin-top: 5px;
  }
}

.c-text-columns__col .c-btn {
  max-width: 262px;
  width: 100%;

  .c-text-columns--linktree & {
    max-width: none;
  }
}

.c-text-columns__picture {
  margin: 0 auto var(--spacer-small);
  display: block;
  text-align: center;
}

.c-text-columns__image,
.c-text-columns__image[width] {
  max-width: 100%;
  height: auto;
}

.c-text-columns__image--circled {
  border-radius: 50%;
}

/* ==========================================================================
   #ICONS INLINE
   ========================================================================== */

.c-icons-inline {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

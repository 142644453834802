.o-logo {
  width: var(--logo-width);
  height: var(--logo-height);
}

.c-header .o-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  background-image: url('~assets/images/logo-original.svg');

  @include bp-down(380px) {
    transform: translateX(-60%);
  }
}

.o-logo__image {
  visibility: hidden;

  .page-template-template-flexible-content &,
  .blog &,
  .archive:not(.woocommerce) & {
    visibility: visible;
    background: none;
  }
}

.o-page__inner:has(.o-main > .c-napoleon:first-child) .o-logo__image {
  visibility: hidden;
}

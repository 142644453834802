/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding-top: var(--spacer-medium);
  padding-bottom: var(--spacer-medium);
  overflow: hidden;

  @include bg-dark;

  @include bp-down(medium) {
    padding-left: var(--spacer-small);
    padding-right: var(--spacer-small);
    flex-direction: column;

    .o-wrapper {
      width: 100%;
    }
  }
}

.c-footer__menu--primary .c-menu__link {
  text-decoration: none;
}

.c-footer__menu .c-menu__link {
  display: inline-block;
  color: $color-white;
  transition: $global-transition;

  &:hover {
    color: $color-orange;
  }
}

.c-footer__menu .c-menu__item {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1px;

  + .c-menu__item {
    margin-top: var(--spacer-xsmall);
  }
}

.c-footer__menu--primary .c-menu {
  columns: 2;
}

.c-footer__menu--secondary {
  @include bp-down(medium) {
    text-align: center;
  }
}

.c-footer__payments {
  display: flex;
  align-items: center;

  @include bp(medium) {
    .c-footer__menu--secondary + & {
      margin-left: var(--spacer-medium);
    }
  }

  @include bp-down(medium) {
    justify-content: center;
  }
}

.c-footer__payments-logo {
  margin: 0 calc(var(--spacer-xsmall) / 2);
}

.c-footer__payments-logo--tpay {
  width: 46px;
}

.c-footer__payments-logo--blik {
  width: 43px;
}

.c-footer__copyright {
  margin-top: var(--spacer-medium);
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.88px;

  @include bp-down(medium) {
    text-align: center;
  }

  &,
  a {
    color: $color-purple;
  }

  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: var(--spacer-xsmall);
  }
}

.c-footer__shop {
  display: flex;
  align-items: flex-end;

  @include bp-down(medium) {
    flex-direction: column;
    align-items: center;
  }
}

.c-footer__menu,
.c-footer * + .c-email,
.c-footer__payments {
  margin-top: var(--spacer-medium);
}

@include bp-down(medium) {
  .c-footer__right,
  .c-footer__left:last-child {
    margin-top: var(--spacer-medium);
  }
}

@include bp(medium) {
  .c-footer__grid {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr minmax(auto, 410px);
    grid-column-gap: 20px;
    grid-row-gap: 0;
  }

  .c-footer__left:first-child {
    grid-area: 1 / 1 / 2 / 2;
  }

  .c-footer__right {
    grid-area: 1 / 2 / 3 / 3;
    justify-self: end;
    width: 100%;
  }

  .c-footer__left:last-child {
    grid-area: 2 / 1 / 3 / 2;
    align-self: end;
  }
}

@include bp-down(medium) {
  .c-footer__grid {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
}

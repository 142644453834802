/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */

/**
 * The list-inline object simply displays a list of items in one line.
 */

.o-list-inline {
  list-style: none;
  margin-left: 0;
  display: flex;
}

.o-list-inline__item {
  // display: inline-block;
}

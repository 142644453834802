.c-text-img {
  padding: var(--spacer-sections) 0;
}

.c-text-img__grid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(12, 1fr);
  justify-content: space-between;

  @include bp(medium) {
    gap: 20px;
  }

  @include bp(xlarge) {
    gap: 40px;
  }
}

.c-text-img__text {
  @include bp(medium) {
    grid-column: span 6;
    align-self: center;
  }

  a:not(.c-btn) {
    color: $color-orange;
  }
}

.c-text-img__media {
  @include bp(medium) {
    grid-column: span 5;
  }
}

.c-text-img--text-left .c-text-img__grid {
  @include bp-down(medium) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 30px;
  }
}

.c-text-img--text-right .c-text-img__grid {
  @include bp-down(medium) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 30px;
  }
}

.c-text-img--text-right .c-text-img__text {
  @include bp(medium) {
    grid-column-end: -1;
  }
}

.c-text-img--text-left .c-text-img__media {
  @include bp(medium) {
    grid-column-end: -1;
  }
}

.c-text-img--text-left .c-text-img__text {
  @include bp(medium) {
    grid-row-start: 1;
  }
}

.c-text-img__media iframe {
  width: 100%;
  aspect-ratio: 860/559;
  height: auto;
}

.c-text-img__text .c-btn {
  margin-top: var(--spacer-small);
  max-width: 232px;
  width: 100%;

  @extend %tile-btn;
}

.c-text-img__title {
  @extend %heading-1;
}

.c-text-img__picture {
  display: block;
}

.c-text-img__image.c-text-img__image {
  display: block;
  margin: 0 auto;
  max-width: 613px;
  width: 100%;
  height: auto;

  @include bp(medium) {
    width: 100%;
    height: 100%;
    object-fit: contain;

    .c-text-img--text-left & {
      float: left;
      max-width: 100%;
      height: auto;
    }

    .c-text-img--text-right & {
      float: right;
    }
  }
}

.c-text-img__text--hidden {
  padding-top: var(--spacer-small);
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: $global-transition;
  opacity: 0;

  &.is-visible {
    opacity: 1;
  }
}

$o-icon__icons: (
  logo: calc(109 / 37),
  basket: calc(30 / 34),
  linkedin: calc(25 / 24),
  instagram: calc(22 / 23),
  youtube: calc(26 / 19),
  facebook: calc(12 / 25),
  pinterest: calc(19 / 25),
  search: calc(29 / 28),
  pointer: calc(27 / 32),
  btn-right: calc(1),
  btn-left: calc(1),
);

.o-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;

  &--inline {
    display: inline-flex;
  }

  @each $icon, $width-ratio in $o-icon__icons {
    &--icon-#{$icon} {
      width: $width-ratio * 1em;
    }
  }
}

.o-icon__symbol {
  display: block;
  width: 100%;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.o-icon--icon-pinterest {
  font-size: 25px;
}

.o-icon--icon-youtube {
  font-size: 19px;
}

.o-icon--icon-instagram {
  font-size: 22px;
}

.o-icon--icon-linkedin,
.o-icon--icon-facebook {
  font-size: 24px;
}

.o-icon--icon-tpay {
  font-size: 24px;
}

.o-icon--icon-blik {
  font-size: 24px;
}

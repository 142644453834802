.c-scroll-to-top {
  display: block;
  width: 80px;
  border-radius: 50%;
  aspect-ratio: 1;
  position: sticky;
  bottom: 20px;
  right: 10px;
  align-self: flex-end;
  float: right;
  transform: rotate(90deg);
  margin-top: -40px;
  margin-bottom: -40px;
  color: $color-white;
  font-size: 80px;
  opacity: 0;
  transition: $global-transition;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
  z-index: 1;

  @include bg-orange;

  @include bp-down(medium) {
    font-size: 54px;
    width: 54px;
    margin: 0;
    position: fixed;
  }

  &.show {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 0 1px $color-orange;
    color: $color-orange;
    background-color: transparent;
  }

  .page-template-template-landing & {
    margin-top: -80px;

    @include bp-down(medium) {
      margin-top: -54px;
    }
  }
}

.c-napoleon {
  @include bg-light;

  padding: var(--spacer-sections) 0;
  overflow: hidden;
}

.c-napoleon__grid {
  display: grid;
  grid-template-columns: 1fr minmax(31%, 560px) 2fr minmax(31%, 560px) 1fr;
  gap: 40px;
  text-align: center;

  @include bp-down(medium) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 40px;
  }
}

.c-page-heading + .c-napoleon__grid {
  margin-top: var(--spacer-medium);
}

.c-napoleon:has(.c-page-heading) {
  padding-top: var(--spacer-medium);
}

.c-napoleon__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:nth-child(odd) {
    @include bp(medium) {
      grid-area: 1 / 2 / 2 / 3;
    }
  }

  &:nth-child(even) {
    @include bp(medium) {
      grid-area: 1 / 4 / 2 / 5;
    }
  }

  .c-btn {
    @extend %section-btn;
  }
}

.c-napoleon__title {
  font-size: 42px;
  line-height: 45px;
}

.c-napoleon__link {
  display: block;
  width: 100%;

  @include bp(medium) {
    margin-bottom: 40px;
  }

  @include bp-down(medium) {
    margin-bottom: 20px;
    max-width: 560px;
  }
}

@include bp(medium) {
  .c-napoleon__link:hover {
    .c-napoleon__picture::before {
      transform: scale(1.1);
    }

    .c-napoleon__img {
      transform: translate(10px, -10px);
    }
  }
}

.c-napoleon__picture {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 0;
  width: 100%;
  aspect-ratio: 1;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    transition: $global-transition;

    @supports not (aspect-ratio: auto) {
      height: 0;
      padding-top: 100%;
    }
  }

  &--orange::before {
    @include bg-orange;
  }

  &--purple::before {
    @include bg-purple;
  }

  &--dark {
    @include bg-dark;
  }
}

.c-napoleon__img {
  max-width: 466px;
  width: 85%;
  height: auto;
  display: block;
  position: relative;
  bottom: 5px;
  transition: transform 400ms linear;

  .c-napoleon__item:nth-child(even) & {
    left: 25px;
  }

  .c-napoleon__item:nth-child(odd) & {
    right: 25px;
  }
}

.c-page-heading {
  padding-top: var(--spacer-medium);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--spacer-medium);
  text-align: center;

  @include bp-down(medium) {
    max-width: 90%;
  }

  @include bp(medium) {
    max-width: 66%;
  }
}

.c-page-heading .c-buttons {
  justify-content: center;
}

.c-stats {
  padding: var(--spacer-sections) 0;
  text-align: center;
}

.c-stats .c-section-heading {
  @extend %heading-1;
}

.c-stats__list {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;

  @include bp(medium) {
    gap: 30px;
  }

  @include bp(large) {
    gap: 40px;
  }

  @include bp(xlarge) {
    gap: 50px;
  }
}

.c-stats__item {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  border-radius: 160px;
  padding: 80px 30px;
  flex: 1 0 0;
  min-height: 320px;
  justify-content: space-between;
  aspect-ratio: 320/400;

  @include bp(xlarge) {
    max-width: 300px;
    min-height: 400px;
  }

  @include bp(xxlarge) {
    max-width: 320px;
  }
}

.c-stats__item:nth-child(even) {
  @include bg-orange;
}

.c-stats__item:nth-child(odd) {
  @include bg-purple;
}

.c-stats__value {
  margin-bottom: 20px;
  max-width: 284px;
  display: flex;
  flex: 1;
  justify-self: stretch;
  justify-content: center;
  align-items: center;
}

.c-stats__value--large {
  font-size: 95px;
  line-height: 0.8;

  @include bp(medium) {
    font-size: 100px;
  }

  @include bp(xxlarge) {
    font-size: 120px;
  }
}

.c-stats__value--small {
  font-size: 66px;
  line-height: 0.9;

  @include bp(medium) {
    font-size: 76px;
  }

  @include bp(xxlarge) {
    font-size: 86px;
  }
}

.c-stats__description {
  padding: 0 20px;
}

.c-stats__cta {
  @extend %heading-1;

  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.c-stats .c-section-heading + .c-stats__list,
.c-stats .c-section-heading__subtitle + .c-stats__list,
.c-stats__list + .c-stats__cta {
  margin-top: 80px;
}

.c-nav-btn {
  width: var(--nav-btn-size);
  margin-bottom: calc(-1 * var(--nav-btn-size));
  transform: translateY(
    calc(calc(var(--header-height) - var(--nav-btn-size)) / 2)
  );
  aspect-ratio: 1;
  border-radius: 50%;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  float: right;

  @include bg-orange;

  position: sticky;
  right: 10px;
  top: -20px;
  z-index: 999999;
  // box-shadow: 0 0 3px 1px rgb(239 239 238 / 50%);

  @supports not (aspect-ratio: auto) {
    height: var(--nav-btn-size);
  }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: var(--nav-btn-size);
    height: var(--nav-btn-size);
    background-color: transparent;
    z-index: -1;
    border-radius: 50%;
    box-shadow: 0 0 0 3px $color-purple;
    animation: navBtn 4s infinite;
  }
}

@keyframes navBtn {
  0% {
    box-shadow: 0 0 0 3px inherit;
  }

  60% {
    box-shadow: 0 0 0 3px inherit;
  }

  70% {
    box-shadow: 0 0 0 8px transparent;
  }

  100% {
    box-shadow: 0 0 0 3px inherit;
  }
}

.c-nav-btn__text {
  text-transform: uppercase;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  font-family: $font-sans-serif;
  letter-spacing: 1px;
  color: $color-white;
  transition: $global-transition;
  pointer-events: none;

  .is-clicked & {
    color: $color-orange;
  }

  @include bp-down(medium) {
    font-size: 14px;
  }
}

.c-nav-btn__close {
  transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 46px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  pointer-events: none;

  @include bp-down(medium) {
    width: 21px;
    transform: translate(-11px, -1px);
  }

  &::before,
  &::after {
    transition-duration: 0.5s;
    position: absolute;
    width: 46px;
    height: 2px;
    background-color: $color-orange;
    content: '';
    left: 0;

    .is-clicked & {
      background-color: $color-white;
    }

    @include bp-down(medium) {
      width: 21px;
      height: 2px;
    }
  }

  /* stylelint-disable */
  &::before {
    top: -20px;

    .is-clicked & {
      transform: rotateZ(45deg) scaleX(1.25) translate(13px, 13px);
    }
  }

  &::after {
    top: 20px;

    .is-clicked & {
      transform: rotateZ(-45deg) scaleX(1.25) translate(12px, -12px);
    }
  }
  /* stylelint-enable */
}

.c-download {
  display: inline-block;
  font-weight: 700;
  line-height: 1.21;
  font-size: inherit;
}

.c-download__link {
  display: flex;
  gap: 20px;
  text-decoration: none;
}

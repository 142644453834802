.c-last-posts {
  padding: var(--spacer-sections) 0;
  overflow: hidden;
}

.c-last-posts--dark {
  @include bg-dark;
}

.c-last-posts--orange {
  @include bg-orange;
}

.c-last-posts--purple {
  @include bg-purple;
}

.c-last-posts .c-btn {
  margin-top: var(--spacer-medium);

  @extend %section-btn;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: transparent;
  position: absolute;
  max-width: var(--wrapper-width);
  width: 100%;
  height: calc(var(--logo-height) + 2 * var(--header-vpadding));
  left: 50%;
  transform: translateX(-50%);
  padding: var(--header-vpadding) var(--wrapper-hspace);
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.c-header--landing {
  position: relative;
}

.c-header__search-social {
  margin-right: calc(var(--logo-width) / 2);
  display: flex;
  align-items: center;

  @include bp-down(1180px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: var(--spacer-xsmall);
  }
}

.c-header__search-social .c-social-media {
  @include bp-down(1180px) {
    margin-left: calc(-1 * var(--spacer-xsmall));
  }

  @include bp-down(medium) {
    display: none;
  }
}

.c-header__basket {
  position: relative;
  margin-left: calc(var(--logo-width) / 2);
  align-self: center;
  justify-self: end;
  font-size: 34px;
  cursor: pointer;
  padding: var(--spacer-xsmall);
  color: $color-black;

  &:hover {
    color: $color-orange;
  }

  @include bp-down(2000px) {
    margin-right: calc(var(--nav-btn-size) / 2);
  }

  @include bp-down(xlarge) {
    margin-right: calc(var(--nav-btn-size) - 10px);
  }
}

.page-template-template-flexible-content,
.blog,
.archive:not(.woocommerce) {
  .c-header__basket:hover {
    color: $color-hover;
  }
}

.c-header__basket-cart-count {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  bottom: 0;
  left: 0;
  font-family: $font-sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: $color-white;
  background-color: $color-orange;
}

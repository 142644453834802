.c-price-comparision {
  padding: var(--spacer-sections) 0;

  .glide {
    max-width: 420px * 3;
    margin: 0 auto;
  }
}

@each $theme, $bg-color in $color-themes {
  .c-price-comparision [class*='--#{$theme}'] {
    background-color: $bg-color;
  }
}

.c-price-comparision [class*='--dark'] {
  color: $color-light-grey;
}

.c-price-comparision .c-section-heading {
  @extend %heading-1;
}

.c-price-comparision__grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;

  @include bp(medium) {
    gap: 40px;
  }
}

.c-price-comparision__plan,
.c-price-comparision__heading {
  padding: var(--spacer-small);
}

.c-price-comparision__plan {
  border-radius: $global-radius;
  flex: 1 1 0;
  max-width: 420px;

  hr {
    border: none;
    border-top: 1px solid $color-black;
  }

  hr + h3 {
    margin-bottom: 0;
  }

  .c-buttons {
    justify-content: center;
  }

  p + .o-list-emoticons {
    margin-top: -0.5rem;
  }
}

.c-price-comparision__heading {
  border: 1px solid $color-black;
  border-radius: 2 * $global-radius;
  text-align: center;

  .name {
    font-size: 1rem;

    @include bp(medium) {
      font-size: 1.45rem;
    }
  }

  .price {
    font-size: 1.5rem;

    @include bp(medium) {
      font-size: 2rem;
    }
  }

  .period {
    font-size: 0.85rem;

    @include bp(medium) {
      font-size: 1.2rem;
    }
  }

  span {
    display: block;
  }
}

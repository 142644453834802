@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

/* stylelint-disable */
/* stylelint-enable */

.c-slider .glide__arrow {
  border: 0;
  border-radius: 50%;
  margin-left: 15px;
  padding: 0;
  font-size: 50px;
  color: $color-black;
  background-color: transparent;
  transition: $global-transition;
  cursor: pointer;

  &:hover {
    color: $color-white;
    background-color: $color-black;
  }

  &:first-child {
    margin-left: 0;
  }

  @include bp-down(small) {
    margin-left: 5px;
    font-size: 40px;
  }

  @include bp(medium) {
    font-size: 45px;
  }

  @include bp(large) {
    font-size: 50px;
  }

  @include bp(xlarge) {
    font-size: 70px;
  }
}

.c-emoticon-content {
  list-style: none;
  margin: var(--spacer-medium) 0;

  &:last-child {
    margin-bottom: 0;
  }

  + .c-text-columns__title.c-text-columns__title.c-text-columns__title.c-text-columns__title {
    margin-top: var(--spacer-sections);
  }
}

.c-emoticon-content__item {
  display: flex;
  gap: 40px;
  margin-bottom: var(--spacer-medium);

  @include bp-down(small) {
    gap: 20px;
    margin-bottom: 1.3rem;
  }
}

.c-emoticon-content__emoticon {
  font-size: 3rem;
  text-align: center;
  flex-shrink: 0;

  @include bp-down(small) {
    font-size: 2.5rem;
    line-height: 1;
  }
}

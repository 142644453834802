.c-404 {
  margin: var(--spacer-medium) 0;
}

.c-404__picture {
  display: block;
  max-width: 522px;
  margin-left: auto;
  margin-right: auto;
}

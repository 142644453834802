/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@font-face {
  font-family: recifedisplay;
  src: url('~assets/fonts/recifedisplayweb-book.woff2') format('woff2'),
    url('~assets/fonts/recifedisplayweb-book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: mabry;
  src: url('~assets/fonts/mabry-light.otf') format('otf'),
    url('~assets/fonts/mabry-light.woff2') format('woff2'),
    url('~assets/fonts/mabry-light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: mabry;
  src: url('~assets/fonts/mabry-bold.otf') format('otf'),
    url('~assets/fonts/mabry-bold.woff2') format('woff2'),
    url('~assets/fonts/mabry-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

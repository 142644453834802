/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

h1,
.heading-1,
%heading-1 {
  font-family: $font-serif;
  font-weight: 400;

  @include bp(medium) {
    font-size: 46px;
    line-height: 52px;
  }

  @include bp(xlarge) {
    font-size: 50px;
    line-height: 1.12;
  }

  @include bp(xxlarge) {
    font-size: 55px;
  }

  @include bp-down(medium) {
    font-size: 28px;
    line-height: 1.25;
  }

  @include bp-down(small) {
    font-size: 24px;
  }

  @media (min-width: 1200px) and (max-height: 768px) {
    font-size: 46px;
    line-height: 1.12;
  }
}

h2,
.heading-2,
%heading-2 {
  font-family: $font-serif;
  font-weight: 400;
  line-height: 1.125;

  @include bp(medium) {
    font-size: 32px;
  }

  @include bp(xlarge) {
    font-size: 40px;
  }

  @include bp(xxlarge) {
    font-size: 42px;
  }

  @include bp-down(medium) {
    font-size: 28px;
  }

  @include bp-down(small) {
    font-size: 20px;
  }

  @media (min-width: 1200px) and (max-height: 768px) {
    font-size: 36px;
  }
}

.heading-label,
%heading-label {
  font-family: $font-sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;

  @include bp-down(small) {
    font-size: 14px;
  }
}

h3,
.heading-3,
%heading-3 {
  font-family: $font-serif;
  font-weight: 400;

  @include bp(medium) {
    font-size: 32px;
    line-height: 1.2;
  }

  @include bp-down(medium) {
    font-size: 28px;
    line-height: 35px;
  }

  @include bp-down(small) {
    font-size: 20px;
    line-height: 1.2;
  }
}

%heading-product-title {
  font-family: $font-sans-serif;
  font-weight: 400;
  line-height: 1.3;

  @include bp(medium) {
    font-size: 22px;
  }

  @include bp-down(medium) {
    font-size: 16px;
  }

  @include bp-down(400px) {
    font-size: 14px;
  }
}

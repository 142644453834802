/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();

  margin-bottom: 2em;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-post__wrapper {
  @include bp(medium) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0 40px;
  }
}

.c-post__heading,
.c-post__content,
.c-post__footer {
  @include bp(medium) {
    grid-column: 2 / span 10;
  }

  @include bp(xxlarge) {
    grid-column: 3 / span 8;
  }
}

.c-post__heading {
  text-align: center;
  padding: var(--spacer-small) 0 0;

  @include bp(medium) {
    padding: var(--spacer-medium) 0 0;
  }
}

.c-post__categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.c-post__categories-link {
  @extend %heading-label;

  font-size: 14px;
  color: $color-black;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: $global-transition;

  &:hover {
    border-bottom-color: $color-black;
  }
}

.c-post__thumbnail {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  margin-top: var(--spacer-medium);
}

.c-post__content {
  padding: var(--post-content-padding) var(--post-content-padding) 0;

  [class*='wp-image'] {
    height: auto;
    border-radius: 10px;

    @include bp-down(medium) {
      max-width: 100%;
    }
  }

  .wp-caption,
  p > .size-full,
  p > .size-large {
    @include bp(small) {
      margin-left: calc(-1 * var(--post-content-padding));
      margin-right: calc(-1 * var(--post-content-padding));
      width: calc(100% + 2 * var(--post-content-padding)) !important;
    }
  }

  .wp-caption [class*='wp-image'] {
    width: 100%;
  }

  @include bp-down(small) {
    padding-left: 0;
    padding-right: 0;

    .wp-caption,
    p > .size-full,
    p > .size-large {
      width: 100%;
    }
  }
}

.c-post__banner {
  margin-top: var(--spacer-small);
  margin-bottom: var(--spacer-small);
  margin-left: calc(-1 * var(--post-content-padding));
  margin-right: calc(-1 * var(--post-content-padding));
}

.c-post__meta {
  padding: var(--spacer-small) 0;
  display: flex;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  justify-content: flex-start;
  flex: 1;
  gap: var(--spacer-xsmall);

  @include bp-down(medium) {
    font-size: 14px;
  }

  @include bp-down(small) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.c-post__meta--link {
  color: $color-black;
  text-decoration: none;

  @include bp(small) {
    margin-left: auto;
    justify-self: flex-end;
  }

  .o-icon {
    @include bp-down(small) {
      order: -1;
    }
  }
}

.c-post__meta--link,
.c-post__meta--reading-time,
.c-post__meta--date {
  gap: var(--spacer-xsmall);
  display: flex;
  align-items: center;

  .o-icon {
    font-size: 27px;

    @include bp-down(small) {
      font-size: 22px;
    }
  }
}

.c-post__footer {
  padding: var(--spacer-medium) 0;
  text-align: center;

  .c-btn {
    @extend %section-btn;
  }
}

.c-post__password-form {
  text-align: center;

  .input-password {
    padding: 17px 20px;
    border: 1px solid $color-black;
    font-family: $font-sans-serif;
    font-size: 19px;
    line-height: 22px;
    width: 100%;
    color: $color-black;
    background-color: $color-light-grey;

    &:focus,
    &:focus-visible {
      outline-color: $color-orange;
    }

    @include bp-down(small) {
      font-size: 16px;
    }
  }
}

.c-hero {
  overflow: hidden;
}

.c-hero__grid {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 20px;

  @include bp(medium) {
    max-height: 750px;
    height: calc(90vh - 51px - var(--header-height));

    .c-hero--newsletter & {
      max-height: none;
      height: auto;
    }
  }

  @include bp(xlarge) {
    gap: 40px;
  }

  @include bp-down(medium) {
    flex-direction: column;
    align-items: center;
  }
}

.c-hero__content {
  @include bp(medium) {
    flex-grow: 1;
    padding: var(--spacer-medium) 0;
    max-width: 990px;
    align-self: center;

    .c-hero--newsletter & {
      max-width: 700px;
    }
  }

  @include bp-down(medium) {
    padding: var(--spacer-small) 0;
    text-align: center;
  }
}

.c-hero__deco {
  @include bp(medium) {
    align-self: stretch;
    display: flex;
    align-items: flex-end;
    min-width: 40%;
  }

  @include bp(large) {
    max-width: 44%;
    flex-shrink: 0;

    .c-hero--newsletter & {
      flex-grow: 1;
    }
  }

  @media (min-width: 1024px) and (min-height: 1000px) {
    margin-top: -15px;
  }
}

.c-hero__picture {
  display: flex;
  align-items: flex-end;

  @include bp-down(medium) {
    max-height: 38vh;
    height: 100vh;
    width: 100%;
    display: block;
  }

  @include bp-down(small) {
    max-height: 35vh;
  }

  @include bp(medium) {
    min-height: 500px;
    margin: 0 auto;
    height: 100%;
  }

  @include bp(xlarge) {
    max-width: 735px;
  }
}

.c-hero__picture.hero-maraton-landing {
  align-items: flex-start;

  @media (min-width: 800px) and (min-height: 1000px) {
    position: relative;
    top: -2rem;
  }
}

.c-hero__img {
  display: block;
  max-width: 100%;
  margin-bottom: -2px;
  max-height: 740px;
  object-fit: contain;
  object-position: bottom;
  width: 100%;
  height: 100%;
}

/* stylelint-disable */
.page-template-template-landing .c-neon + .c-hero {
  .c-hero__grid {
    @include bp(medium) {
      max-height: none;
      height: auto;
    }
  }

  .c-hero__picture {
    @include bp(medium) {
      max-height: 700px;
    }
  }
}

.c-hero--newsletter:has(.ml-form-successBody.row-success:not([style="display:none"])) {
  .row-success {
    border-bottom: 1px solid $color-black;
  }

  .c-hero__content > div:first-child {
    display: none;
  }
}

/* stylelint-enable */

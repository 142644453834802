.c-newsletter-form {
  display: flex;
  padding: var(--spacer-medium);
  scroll-margin-top: 50px;

  @include bp-down(medium) {
    flex-direction: column;
  }

  @include bp-down(small) {
    padding: var(--spacer-small);
  }

  .c-btn {
    margin-top: var(--spacer-xsmall);

    @include bp(medium) {
      flex-shrink: 0;
      min-width: 175px;
    }

    @include bp(small) {
      margin-top: 0;
    }
  }
}

.c-newsletter-form:not(.c-newsletter-form--footer) {
  @include bg-orange;

  @include bp(medium) {
    display: grid;
    align-items: center;
    grid-column-gap: var(--spacer-small);
    grid-row-gap: var(--spacer-small);
  }

  @include bp-between(medium, large) {
    grid-template-columns: minmax(auto, 382px) auto;

    form {
      grid-area: 2 / 1 / 3 / 3;
    }
  }

  @include bp(large) {
    grid-template-columns: minmax(auto, 382px) auto minmax(auto, 416px);
    grid-column-gap: calc(var(--spacer-medium) / 2);
  }

  @include bp(xlarge) {
    grid-column-gap: var(--spacer-medium);
  }
}

.c-hero + .c-newsletter-form__container {
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
  }

  &::before {
    top: 0;

    @include bg-purple;
  }

  &::after {
    bottom: 0;
  }
}

.c-newsletter-form__container:has(+ [class*='--green'])::after,
[class*='--green'] + .c-newsletter-form__container::before {
  @include bg-green;
}

.c-newsletter-form__container:has(+ [class*='--orange'])::after,
[class*='--orange'] + .c-newsletter-form__container::before {
  @include bg-orange;
}

.c-newsletter-form__container:has(+ [class*='--dark'])::after {
  @include bg-dark;
}

.c-newsletter-form__container:has(+ [class*='--purple'])::after {
  @include bg-purple;
}

.c-newsletter-form__container .o-wrapper {
  @include bp-down(medium) {
    padding-left: 0;
    padding-right: 0;
  }
}

.ml-form-fieldRow,
.ml-form-checkboxRow,
.c-newsletter-form__row {
  margin-top: var(--spacer-xsmall);

  &:first-child {
    margin-top: 0;
  }
}

.ml-form-embedContainer .ml-field-group,
.c-newsletter-form__row--signup {
  display: flex;

  @include bp-down(small) {
    flex-direction: column;
  }
}

.c-newsletter-form__group + .c-newsletter-form__group {
  margin-top: var(--spacer-small);
}

.c-newsletter-form__input,
.ml-form-embedContainer .ml-field-group input {
  border-radius: $global-radius;
  padding: 20px 30px;
  border: 1px solid $color-black;
  color: $color-black;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: $font-sans-serif;
  background-color: transparent;
  flex-grow: 1;
  flex-shrink: 1;

  &[name='newsletter-email'] {
    padding: 0 50px 0 30px;
    margin-right: -$global-radius;
    border-radius: $global-radius 0 0 $global-radius;
    min-width: 100px;
  }

  &:focus,
  &:focus-visible {
    outline: 0;
  }

  @include bp-down(medium) {
    &,
    &[name='newsletter-email'] {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }

  @include bp-down(small) {
    &,
    &[name='newsletter-email'] {
      margin-right: 0;
      border-radius: $global-radius;
    }
  }
}

.c-newsletter-form__row--signup,
.c-newsletter-form__checkbox-label {
  @include bp-down(medium) {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ml-form-checkboxRow .checkbox,
.c-newsletter-form__checkbox-label {
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  @include bp(medium) {
    font-size: 14px;
    line-height: 17px;
  }

  @include bp-down(medium) {
    font-size: 12px;
    line-height: 14px;
  }
}

.c-newsletter-form__checkbox {
  opacity: 0;
  position: absolute;
}

.c-newsletter-form__checkbox-circle {
  display: none;
}

// Unselected checkbox
.ml-form-checkboxRow .label-description::before,
.c-newsletter-form__checkbox + .c-newsletter-form__checkbox-circle {
  display: inline-block;
  margin-right: 7px;
  width: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 0 1px $color-black;
  border: 2px solid transparent;
  background: $color-orange;
  transition: $global-transition;
  flex-shrink: 0;
  flex-grow: 0;

  @supports not (aspect-ratio: auto) {
    height: 16px;
  }
}

// Selected checkbox
.ml-form-checkboxRow
  input[type='checkbox']:checked
  ~ .label-description::before,
.c-newsletter-form__checkbox:checked + .c-newsletter-form__checkbox-circle {
  border: 2px solid $color-orange;
  background-color: $color-black;
}

.c-newsletter-form__title {
  @extend %heading-1;
}

.c-newsletter-form__description {
  margin-bottom: var(--spacer-small);
}

.c-newsletter-form__title,
.c-newsletter-form__description {
  @include bp(medium) {
    margin-bottom: 0;
  }

  @include bp-down(medium) {
    text-align: center;
  }
}

// Styles just for component placed in footer
.c-newsletter-form--footer {
  padding: 0;
  flex-direction: column;
  background-color: transparent;
  max-width: 560px;

  .c-newsletter-form__title {
    color: $color-purple;

    @include bp(medium) {
      margin-bottom: 30px;
      font-size: 32px;
      line-height: 40px;
    }

    @include bp-down(medium) {
      margin-bottom: 20px;
    }
  }

  .c-newsletter-form__input {
    border-color: $color-purple;
    color: $color-white;
  }

  // Unelected checkbox
  .c-newsletter-form__checkbox + .c-newsletter-form__checkbox-circle {
    background: $color-black;
    box-shadow: 0 0 0 1px $color-white;
  }

  // Selected checkbox
  .c-newsletter-form__checkbox:checked + .c-newsletter-form__checkbox-circle {
    border: 2px solid $color-black;
    background-color: $color-white;
  }
}

.c-newsletter-form__response {
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.c-newsletter-form__response.is-success ~ .c-newsletter-form__row {
  display: none;
}

// Styles for ML forms only
/* stylelint-disable */
.ml-form-checkboxRow .checkbox {
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
  }

  .label-description {
    position: relative;
    padding-left: 25px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
    }
  }

  a {
    color: inherit;
  }
}

.ml-form-recaptcha {
  float: none !important;
}

.ml-form-embedSubmit {

  text-align: center;

  button[type='submit'] {
    margin-top: 1.3rem;
    @extend %c-btn;
    @extend %c-btn--primary;

    @include bg-dark;
  }
}
/* stylelint-enable */

.o-products-listing {
  clear: both;
  display: grid;
  padding-bottom: var(--spacer-sections);

  @include bp(medium) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 60px 40px;
  }

  @include bp(large) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 60px 40px;
  }

  @include bp-down(medium) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
  }

  @include bp-down(small) {
    gap: 20px;
  }
}

.o-products-listing--2 .c-ft-products__item:first-child {
  @include bp(large) {
    grid-column-start: 2;
  }
}

.c-testimonials {
  padding: var(--spacer-sections) 0;
}

.c-testimonials__picture {
  display: block;
  width: 100%;
  opacity: 0.5;
  transition: $global-transition;
  transform: scale(0.91);
}

.c-testimonials .glide__slide--active {
  .c-testimonials__picture {
    opacity: 1;
    transform: scale(1);
  }
}

.c-testimonials__grid {
  display: flex;
  width: 100%;
  gap: var(--spacer-medium);
  align-items: center;

  @include bp-down(xlarge) {
    flex-direction: column;
  }
}

.c-testimonials__content {
  @include bp-down(xlarge) {
    flex: 1;
    text-align: center;
  }

  @include bp(xlarge) {
    width: 30%;
  }
}

.c-testimonials .glide {
  position: relative;
  overflow: hidden;

  @include bp-down(xlarge) {
    // width: 100vw;
  }

  @include bp(xlarge) {
    margin-right: calc(-1 * var(--wrapper-hspace));
    width: calc(70% + var(--wrapper-hspace));
  }
}

.c-testimonials__image {
  border-radius: 10px;
  display: block;
}

.c-instabar {
  @include bg-dark;

  overflow: hidden;
  width: 100%;

  .o-wrapper {
    padding: 0;
    max-width: var(--wrapper-width);
  }

  .c-ft-posts + & {
    padding-top: var(--spacer-medium);
  }
}

.c-instabar__title {
  margin: 0;
  padding: $spacer-small 0;
  text-align: center;

  @include bp(medium) {
    font-size: 35px;
    line-height: 1.2;
  }

  @include bp(xlarge) {
    font-size: 45px;
    line-height: 62px;
  }

  @include bp-down(medium) {
    font-size: 28px;
    line-height: 35px;
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: $color-orange $color-black;

    &::-webkit-scrollbar {
      background-color: $color-black;
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-orange;
      border-radius: 5px;
    }
  }
}

.c-instabar__slider {
  list-style: none inside;
  margin: 0;
  padding: 0;
}

.c-instabar__slide {
  width: 100%;
  max-width: 320px;
  margin-bottom: 0;
}

.c-instabar__link {
  display: block;
  width: 100%;
}

.c-instabar__picture.c-instabar__picture {
  aspect-ratio: 1;
  width: 100%;
  display: block;
  overflow: hidden;

  @supports not (aspect-ratio: auto) {
    position: relative;
    padding-top: 100%;
    height: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    @supports not (aspect-ratio: auto) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.o-single-product {
  clear: both;
  padding-bottom: var(--spacer-sections);
  padding-top: var(--spacer-medium);

  @include bp(medium) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;
  }

  @include bp(large) {
    gap: 40px;
  }
}

.o-single-product__images {
  position: relative;
  overflow: hidden;

  @include bp(medium) {
    grid-column: 1 / span 6;
  }

  @include bp(large) {
    grid-column: 1 / span 5;
  }

  @include bp(xxlarge) {
    grid-column: 1 / span 5;
  }

  /* stylelint-disable */
  img {
    border-radius: 10px;
    cursor: pointer;
  }

  span.onsale.onsale {
    left: auto;
    right: 0;
    top: 0;
    color: transparent;
    background: transparent;

    &::before {
      content: 'promka!';
      display: flex;
      align-items: center;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 30px;
      padding: 5px 10px;
      font-size: 14px;
      border: 1px solid $color-black;
      color: $color-orange;
      background-color: $color-white;

      @extend %heading-label;

      z-index: 1;
    }
  }
  /* stylelint-enable */
}

.o-single-product__content {
  @include bp(medium) {
    grid-column: 7 / span 6;
  }

  @include bp(xxlarge) {
    grid-column: 7 / span 5;
  }

  .c-buttons .c-btn--quaternary {
    flex-grow: 1;

    @include bp(xxlarge) {
      width: 280px;
      flex-grow: 0;
    }
  }

  .c-buttons .c-btn--secondary {
    flex-grow: 1;
  }

  /* stylelint-disable */
  .product_title,
  .price {
    @extend %heading-2;
  }

  a:not(.c-btn) {
    color: $color-orange;
  }

  .price {
    color: $color-orange;
  }

  .price ins {
    text-decoration: none;
  }

  .product_meta {
    margin-top: var(--spacer-small);
    font-size: 14px;

    @extend %heading-label;

    a {
      color: $color-black;
    }
  }

  .variations {
    th, td {
      text-align: left;
      width: 100%;
      display: block;
    }

    td select {
      width: 100%;
      max-width: none;
    }

    th + td {
      margin-top: var(--spacer-small);
    }

    .reset_variations {
      @extend %heading-label;
      color: $color-black;
      text-align: center;
      display: block;
      margin-top: var(--spacer-xsmall);
    }
  }

  .woocommerce-variation-price {
    text-align: right;
  }
  /* stylelint-enable */
}

.o-single-product .c-product-gallery__slider {
  display: flex;
  justify-content: space-between;

  .c-product-gallery__thumbs-wrapper {
    overflow: auto;
    margin: 0 30px 0 0;
    flex: 1 0 113px;
    order: 1;
    position: relative;

    @include bp(medium) {
      display: block;
    }

    @include bp-down(medium) {
      display: none;
    }
  }

  .c-product-gallery__thumbs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .c-product-gallery__slides {
    order: 2;
    border-radius: 10px;
    overflow: hidden;
  }

  .glide__bullet--active {
    opacity: 0.7;
  }
}
/* stylelint-disable */
.single_add_to_cart_button + .added_to_cart.wc-forward,
.add_to_cart_button + .added_to_cart.wc-forward {
  display: none;
}

.iworks-omnibus {
  font-size: 0.75rem;
}

.price + .iworks-omnibus {
  margin-top: -0.75rem;
}

.o-single-product .cart .quantity,
.c-btn .iworks-omnibus {
  display: none;
}
/* stylelint-enable */

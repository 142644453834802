.c-text-media {
  padding: var(--spacer-sections) 0;
  overflow: hidden;
}

.o-main .c-text-media.c-text-media--purple:last-of-type,
.o-main .c-text-media.c-text-media--green:last-of-type {
  padding: var(--spacer-medium) 0 0;

  @include bp-down(medium) {
    padding-bottom: var(--spacer-small);
  }
}

.c-text-media__grid {
  display: grid;
  gap: 40px;
  align-items: center;

  @include bp(medium) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 20px;
  }

  @include bp(xlarge) {
    gap: 40px;
  }
}

.c-text-media__text {
  @include bp(medium) {
    grid-column: span 5;
  }

  .c-text-media--text-left & {
    @include bp(medium) {
      order: 0;
    }
  }

  .c-text-media--text-right & {
    @include bp(medium) {
      grid-column: 8 / span 5;
    }
  }
}

.c-text-media__media {
  @include bp(medium) {
    grid-column: span 6;
  }

  .c-text-media--text-left & {
    @include bp(medium) {
      order: 1;
      grid-column: 7 / span 6;
    }
  }

  iframe {
    width: 100%;
    aspect-ratio: 1280/720;
    height: auto;
  }

  @supports not (aspect-ratio: auto) {
    .c-embed--video {
      padding-top: 56%;
      height: 0;
      position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.c-text-media__text .c-buttons {
  margin-top: var(--spacer-small);
}

.c-text-media__title {
  @extend %heading-1;
}

.c-text-media__picture {
  display: block;
  height: 100%;

  &.landing-kasia-long-collage {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}

.c-text-media__image {
  display: block;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  height: 100%;
  width: auto;
  max-height: 50vh;

  @include bp(medium) {
    max-height: 75vh;
  }
}

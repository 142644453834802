.c-neon__link {
  color: $color-black;
}

.c-neon {
  position: relative;
  overflow: hidden;
  font-family: $font-serif;
  padding: 15px 0;
  color: $color-black;
  height: var(--neon-height);

  @include bp(medium) {
    font-size: 45px;
    line-height: 60px;
  }

  @include bp-down(medium) {
    font-size: 28px;
    line-height: 30px;
  }

  &--purple {
    @include bg-purple;
  }

  &--orange {
    @include bg-orange;
  }

  &--dark {
    @include bg-dark;

    &,
    .c-neon__link {
      color: $color-white;
    }
  }
}

.c-neon--top {
  margin-top: calc(-1 * var(--neon-height));

  @include bp-down(medium) {
    margin-top: -20px;
  }
}

.c-neon--bottom {
  z-index: 1;
  margin-bottom: calc(-1 * var(--neon-height));

  @include bp-down(medium) {
    margin-bottom: -20px;
  }
}

.c-neon__content {
  white-space: nowrap;
  position: absolute;
  display: flex;
  width: 100%;
}

.c-neon--right .c-neon__content {
  direction: rtl;
}

.c-neon__text {
  margin-left: 50px;
  width: auto;
}

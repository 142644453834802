/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 1rem; /* [1] */ /* 16px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  padding: 0;
  width: 100%;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;

  @include bp(large) {
    font-size: 22px;
    line-height: 1.45;
  }

  scrollbar-width: thin;
  scrollbar-color: $color-orange $color-light-grey;

  &::-webkit-scrollbar {
    background-color: $color-light-grey;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-orange;
  }
}

.blog,
.archive:not(.woocommerce) {
  background-color: $color-purple;
}

/* stylelint-disable */
#wpadminbar {
  @media screen and (max-width: 600px) {
    position: fixed !important;

    a.ab-item {
      scale: 0.7;
      width: 40px !important;
      overflow: hidden;
    }
  }
}
/* stylelint-enable */

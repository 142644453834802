/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */

.c-comment-form {
  margin-top: 2em;

  label {
    display: block;
  }

  textarea {
    max-width: 100%;
  }
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* stylelint-disable */
.woocommerce-Reviews {
  position: relative;

  .stars a {
    color: $color-purple;
  }


  .comment-form {
    input[type="text"],
    input[type="email"],
    textarea {
      @extend %input-text;
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      min-height: 175px;
    }
  }

  .comment-form-comment label,
  .comment-form-author label,
  .comment-form-email label {
    display: none;
  }

  .comment-reply-title {
    @extend %heading-2;
    display: block;
    margin-bottom: var(--spacer-xsmall);
  }

  .form-submit {
    text-align: right;

  }
}
.o-single-product__content .woocommerce-review-link.woocommerce-review-link,
.woocommerce-review-link {
  display: inline-block;
  margin-left: var(--spacer-xsmall);
  color: $color-black;
}

.woocommerce .star-rating {
  color: $color-purple;
  float: none;
  margin-bottom: var(--spacer-small);
  display: inline-block;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  margin: 0;
}

.woocommerce #review_form #respond{
  @include bp(large) {
    width: 50%;
    margin: 0 auto;
  }
}

.woocommerce #comments + #review_form_wrapper {
  margin-top: var(--spacer-medium);
}
/* stylelint-enable */

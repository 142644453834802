.c-accordion + .c-accordion {
  margin: var(--spacer-small) 0 var(--spacer-small);
}

.c-accordion__button {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid $color-black;
  padding-bottom: var(--spacer-small);
  padding-left: 0;
  text-align: left;
  background: none;
  cursor: pointer;
  padding-right: 20px;
  color: $color-black;

  .c-accordion--simple & {
    border-bottom: 0;
    width: auto;
  }

  .c-accordion--expanded & {
    cursor: auto;
  }

  &::before {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-13px);
    content: '';
    display: block;
    width: 16px;
    height: 9px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAxIDYuODE0IDYgNy4xMjQtNiIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=');
    transition: $global-transition;

    .is-visible & {
      transform: translateY(-13px) rotate(180deg);
    }

    .c-accordion--expanded & {
      display: none;
    }
  }
}

.c-accordion__title {
  @extend %heading-label;

  color: $color-black;
}

.c-accordion__content {
  padding-top: var(--spacer-small);
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: $global-transition;
  opacity: 0;

  .is-visible & {
    opacity: 1;
  }

  .c-accordion--expanded & {
    opacity: 1;
    max-height: 100%;
  }

  .c-accordion--simple & {
    padding-top: 0;
    padding-bottom: var(--spacer-small);
  }

  > *:last-child {
    padding-bottom: var(--spacer-medium);
  }

  a {
    color: $color-orange;
  }
}

.c-accordion__faq {
  margin-top: var(--spacer-medium);
}

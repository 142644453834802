.c-get-crazy {
  padding: var(--spacer-sections) 0;
}

.c-get-crazy .c-section-heading {
  @extend %heading-1;

  text-align: center;
}

.c-get-crazy__grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  justify-content: space-between;
  list-style: none inside;
  margin: var(--spacer-medium) 0 0;

  @include bp(large) {
    gap: 40px;
  }
}

.c-get-crazy__item {
  border-radius: 35px;
  grid-column: span 12;
  text-align: center;
  z-index: 1;

  @include bg-dark;

  color: $color-purple;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: stretch;

  @include bp(medium) {
    grid-column: span 6;
    align-self: stretch;
    display: flex;
  }

  @include bp(xlarge) {
    flex-direction: row;
  }
}

.c-get-crazy__media,
.c-get-crazy__content {
  align-self: stretch;

  @include bp(xlarge) {
    flex: 1 1 50%;
  }
}

.c-get-crazy__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacer-small);

  @include bp(xlarge) {
    padding: 35px;
  }

  .c-btn {
    width: 100%;

    .c-get-crazy__single & {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.c-get-crazy__picture {
  display: block;
  width: 100%;
  height: 100%;

  @include bp-down(xlarge) {
    aspect-ratio: 363/236;
  }
}

.c-get-crazy__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 30px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: mabry, georgia, -apple-system, system-ui, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;
$font-serif: recifedisplay, georgia, 'Times New Roman', serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 800px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1420px,
  full: 1840px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-purple: #ba92f2;
$color-orange: #fe5000;
$color-very-light-grey: #ccc;
$color-light-grey: #efefee;
$color-green: #d5dd31;

// Text
$color-bg: $color-light-grey;
$color-text: $color-black;

// Links
$color-link: $color-orange;
$color-link-current: $color-orange;
$color-hover: $color-light-grey;

$color-nav-link: $color-purple;
$color-nav-link-current: $color-orange;
$color-nav-link-hover: $color-orange;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;

/* Spacers
   ========================================================================== */
:root {
  --spacer-xsmall: 8px;
  --spacer-small: 16px;
  --spacer-medium: 50px;
  --spacer-sections: 60px;
  --nav-btn-size: 54px;
  --logo-width: 90px;
  --logo-height: 49px;
  --header-vpadding: 25px;
  --neon-animation-time: 25s;
  --wrapper-hspace: 20px;
  --wrapper-width: 1920px;
  --timeline-dot: 40px;
  --skew-size: 25px;
  --post-content-padding: 0;
  --neon-height: 60px;

  @media (min-width: map-get($breakpoints, 'small')) {
    --post-content-padding: 20px;
    --spacer-sections: 70px;
  }

  @media (min-width: map-get($breakpoints, 'medium')) {
    --spacer-xsmall: 10px;
    --spacer-small: 20px;
    --spacer-medium: 60px;
    --spacer-sections: 150px;
    --nav-btn-size: 100px;
    --logo-width: 190px;
    --logo-height: 102px;
    --header-vpadding: 30px;
    --neon-animation-time: 30s;
    --wrapper-hspace: 40px;
    --timeline-dot: 44px;
    --skew-size: 80px;
    --post-content-padding: 40px;
    --neon-height: 90px;
  }

  @media (min-width: map-get($breakpoints, 'xlarge')) {
    --wrapper-hspace: 80px;
    --post-content-padding: 80px;
  }

  @media (min-width: map-get($breakpoints, 'xxlarge')) {
    --post-content-padding: 120px;
  }

  @media (min-width: 380px) {
    --logo-width: 124px;
    --logo-height: 67px;
  }

  @media (min-width: 900px) {
    --logo-width: 248px;
    --logo-height: 134px;
  }

  --header-height: calc(var(--logo-height) + 2 * var(--header-vpadding));
}

$spacer-small: 16px;

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */

/**
 * The list-inline object simply displays a list of items in one line.
 */

.o-list-bubbles {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .c-text-columns__title + & {
    margin-top: var(--spacer-medium);
  }
}

.o-list-bubbles li {
  position: relative;
  margin-bottom: 0;
  padding: var(--spacer-small);
  border-radius: $global-radius;
  word-wrap: break-word; /* Make sure the text wraps to multiple lines if long */
  border: 1px solid $color-black;
  color: $color-black;

  @include bp-down(medium) {
    width: 90%;
  }

  @include bp(medium) {
    width: 75%;
  }
}

.o-list-bubbles
  li:not(.o-list-bubbles__item--green):not(.o-list-bubbles__item--purple):not(
    .o-list-bubbles__item--orange) {
  background-color: $color-white;
}

.o-list-bubbles__item--orange {
  background-color: $color-orange;
  align-self: flex-end;
}

.o-list-bubbles__item--green {
  background-color: $color-green;
}

.o-list-bubbles__item--purple {
  background-color: $color-purple;
  align-self: flex-end;
}

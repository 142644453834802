.c-wysiwyg {
  a {
    color: $color-orange;

    &:hover {
      text-decoration: none;
    }
  }

  blockquote {
    margin: var(--spacer-medium);

    @extend %heading-1;

    color: $color-orange;
    text-align: center;
  }

  .wp-caption-text {
    text-align: left;
    font-weight: 200;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
  }

  .c-embed--video {
    position: relative;
    height: 0;
    padding-top: 56%;
  }

  .c-embed--video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.c-wysiwyg:not(.c-post__content) {
  padding-bottom: var(--spacer-sections);
}

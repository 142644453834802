.c-timeline {
  padding: var(--spacer-sections) 0;
  overflow-x: hidden;
}

.c-text-img + .c-timeline {
  padding-top: 0;
}

.c-timeline .c-section-heading {
  @extend %heading-1;

  margin-bottom: var(--spacer-medium);
}

.c-timeline__slider-wrapper {
  // width: 100vw;
  display: flex;
}

.c-timeline .glide__arrows {
  text-align: right;
}

.c-timeline__decor {
  position: relative;
  flex: 0 0 253px;
  display: block;
  margin-right: var(--spacer-medium);
  margin-left: -11px;

  @include bp(xlarge) {
    flex-basis: 282px;
  }

  @include bp-down(medium) {
    flex-basis: 200px;
    margin-left: -32px;
    margin-right: var(--spacer-small);
    top: 9px;
  }

  @include bp-down(small) {
    flex-basis: 150px;
    top: 19px;
  }
}

.c-timeline__decor-img {
  display: block;
  width: 100%;
  height: auto;
}

.c-timeline__slides {
  list-style: none inside;
  position: relative;
  padding-top: 44px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 44px;
    width: 100%;
    height: 1px;
    background-color: $color-black;
  }
}

.c-timeline__slide {
  position: relative;

  @include bp-down(medium) {
    padding-right: 5px;
  }

  &::before {
    content: '';
    display: block;
    width: var(--timeline-dot);
    height: var(--timeline-dot);
    margin-top: calc(-1 * var(--timeline-dot) / 2);
    margin-bottom: calc(var(--spacer-small) + 18px);
    border-radius: 50%;
    margin-left: 18px;
    background-color: $color-black;
    transform: scale(1);
    box-shadow: 0 0 0 18px $color-purple;
    animation: timelineDots 4s infinite;
  }
}

@keyframes timelineDots {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 18px inherit;
  }

  60% {
    box-shadow: 0 0 0 18px inherit;
  }

  70% {
    transform: scale(0.8);
    box-shadow: 0 0 0 12px transparent;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 18px inherit;
  }
}

.c-timeline__title {
  color: $color-purple;

  @extend %heading-1;
}

.c-timeline__slide:nth-child(odd) {
  &::before {
    box-shadow: 0 0 0 18px $color-orange;
    animation-delay: 1s;
  }

  .c-timeline__title {
    color: $color-orange;
  }
}

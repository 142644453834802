/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  margin: 0;
  // align-items: center;
  // justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  max-width: 790px;
  transform: translateX(100%);
  transition: $global-transition;

  @include bg-dark;

  &.is-open {
    right: 0;
    transform: translateX(0);
    z-index: 99999;
  }

  @include bp-down(medium) {
    text-align: center;
  }

  scrollbar-width: thin;
  scrollbar-color: $color-orange $color-black;

  &::-webkit-scrollbar {
    background-color: $color-black;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-orange;
  }
}

.c-main-nav__list.c-main-nav__list {
  margin: auto;

  @include bp(medium) {
    max-width: 56%;
  }
}

.c-main-nav__item {
  list-style: none;
  padding: 0;
  font-family: $font-serif;
  text-transform: lowercase;

  @include bp(medium) {
    margin: 10px 0;
    font-size: 55px;
    line-height: 1.2;
  }

  @media screen and (max-height: 1000px) and (min-width: 800px) {
    font-size: 40px;
  }

  @include bp-down(medium) {
    margin: 20px 0;
    font-size: 28px;
    line-height: 1.25;
  }
}

.c-main-nav__link,
.c-main-nav__sublink {
  color: $color-nav-link;
}

.c-main-nav__link {
  display: block;
  text-decoration: none;
  transition: $global-transition;

  &:hover {
    color: $color-nav-link-current;

    @include bp(medium) {
      transform: translateX(-22%);
    }
  }
}

.c-main-nav__dropdown {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 var(--spacer-medium);
  width: 80%;

  @include bp-down(medium) {
    margin-left: var(--spacer-small);
  }
}

.c-main-nav__subitem {
  list-style: none;

  @include bp(medium) {
    margin: 5px 0;
  }

  @include bp-down(medium) {
    margin: 10px 0;
  }
}

.c-main-nav__sublink {
  display: block;
  text-decoration: none;
  font-size: 80%;
  transition: $global-transition;

  &:hover {
    transform: translateX(calc(-1 * var(--spacer-medium)));

    @include bp-down(medium) {
      transform: translateX(calc(-1 * var(--spacer-small)));
    }
  }
}

.current-menu-item .c-main-nav__link,
.current-menu-item .c-main-nav__sublink {
  text-decoration: none;
  color: $color-nav-link-current;
}

.nav-is-open {
  overflow: hidden;
}

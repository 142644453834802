.xoo-wsc-modal .xoo-wsc-container {
  @include bg-purple;
}

.xoo-wsc-container.xoo-wsc-container,
.xoo-wsc-slider.xoo-wsc-slider {
  @include bp-down(medium) {
    width: 100%;
    padding: 0 var(--wrapper-hspace);
  }
}

.xoo-wsc-modal.xoo-wsc-cart-active {
  z-index: 9999999;
}

.xoo-wsc-header {
  .xoo-wsc-icon-cross {
    top: 110px;
    right: 80px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 26px;
    fill: $color-white;
    background-color: $color-orange;

    @include bp-down(medium) {
      top: 10px;
      right: 10px;
    }

    @media (max-height: 1200px) {
      top: 20px;
      right: 20px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 26px;
      height: 2px;
      left: 50%;
      top: 50%;
      margin-top: -1px;
      margin-left: -13px;
      background-color: $color-white;

      @include bp-down(medium) {
        width: 21px;
        height: 2px;
        margin-left: -10px;
      }
    }

    &::before {
      transform: rotateZ(45deg);
    }

    &::after {
      transform: rotateZ(-45deg);
    }
  }
}

.xoo-wsc-body {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .xoo-wsc-sm-info {
    @include bp-down(medium) {
      font-size: 18px;
      line-height: 1.2;
    }

    @include bp-down(small) {
      font-size: 16px;
      line-height: 1.2;
    }
  }
}

.xoo-wsc-body,
.xoo-wsc-body .amount.amount,
.xoo-wsc-body a {
  font-size: 22px;
  line-height: 1.2;

  @include bp-down(medium) {
    font-size: 18px;
    line-height: 1.2;
  }

  @include bp-down(small) {
    font-size: 16px;
    line-height: 1.2;
  }
}

.xoo-wsc-products {
  overflow-y: auto;
  max-height: 90%;
  scrollbar-width: thin;
  scrollbar-color: $color-orange $color-purple;

  &::-webkit-scrollbar {
    background-color: $color-purple;
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-orange;
  }

  .xoo-wsc-img-col {
    align-self: start;
    max-width: 210px;

    img {
      margin-left: 1px;
      border-radius: 10px;
      display: block;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.xoo-wsc-body,
.xoo-wsc-footer {
  max-width: 560px;
  margin: 0 auto;
  width: 100%;
}

.xoo-wsc-products .xoo-wsc-product {
  padding: 20px 0;
  border-bottom-color: $color-black;
}

.xoo-wsc-product .xoo-wsc-sum-col {
  padding-left: 30px;

  @include bp-down(medium) {
    padding-left: 10px;
  }

  .xoo-wsc-qty-price {
    display: none;
  }

  .xoo-wsc-sm-info {
    flex-direction: column;
    height: 100%;
  }

  .xoo-wsc-sm-left {
    flex-grow: 0;
  }

  .xoo-wsc-sm-right {
    padding: 10px 0 0;
    max-width: none;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
  }

  .xoo-wsc-smr-ptotal {
    order: 0;
    text-transform: uppercase;
  }

  .xoo-wsc-icon-trash {
    order: 1;
  }

  .xoo-wsc-icon-trash::before {
    content: 'Usuń z koszyka';
    font-family: $font-sans-serif;
    font-size: 16px;
    color: #825db5;
  }
}

.xoo-wsc-footer.xoo-wsc-footer {
  padding: 0 0 70px;
  font-size: 22px;

  @include bp-down(medium) {
    padding-bottom: 30px;
  }

  .xoo-wsc-ft-amt {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    font-weight: 700;
  }

  .xoo-wsc-ft-amt-value {
    text-transform: uppercase;
  }
}

.xoo-wsc-ft-buttons-cont {
  margin-top: 20px;
}

.xoo-wsc-empty-cart.xoo-wsc-empty-cart {
  padding: 0;
  align-self: center;

  > span {
    display: block;
    margin-bottom: var(--spacer-medium);
    text-align: center;

    @extend %heading-2;
  }

  > .button.btn {
    margin-top: 60px;
    width: 100%;

    @extend %c-btn;

    color: $color-white;
    border-color: $color-orange !important;
    background-color: $color-orange;

    &:hover,
    &:active {
      color: $color-black;
      border-color: $color-black !important;
    }
  }
}

.xoo-wsc-ft-buttons-cont > .button {
  &.xoo-wsc-ft-btn-checkout {
    @extend %c-btn--secondary;
  }

  &.xoo-wsc-ft-btn-continue {
    @extend %c-btn--quaternary;
  }
}

.xoo-wsc-loader.xoo-wsc-loader {
  background-color: $color-black;
  background-image: url('~assets/images/icon-tail-spin.svg');
  background-repeat: no-repeat;
  background-position: center;
}

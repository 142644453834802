.c-values {
  padding: var(--spacer-sections) 0;
}

.c-values .c-section-heading {
  @extend %heading-1;

  text-align: center;
}

.c-values__grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr);
  justify-content: space-between;
  list-style: none inside;
  margin: var(--spacer-medium) 0 0;

  @include bp(xlarge) {
    gap: 40px;
  }
}

.c-values__item {
  grid-column: span 12;
  text-align: center;
  z-index: 1;

  @include bp(medium) {
    grid-column: span 4;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.c-values__grid--2 {
  .c-values__item {
    @include bp(medium) {
      grid-column: span 6;
    }
  }
}

.c-values__grid--4 {
  .c-values__item {
    @include bp(medium) {
      grid-column: span 3;
    }
  }
}

.c-values__title {
  @extend %heading-2;

  color: $color-orange;
}

.c-values__picture {
  display: block;
  position: relative;

  &::before {
    content: '';
    display: block;
    max-width: 237px;
    width: 100%;
    position: absolute;
    aspect-ratio: 237/106;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: $color-orange;

    @include bp(medium) {
      aspect-ratio: 298/133;
      max-width: 298px;
    }

    @supports not (aspect-ratio: auto) {
      height: 0;
      padding-top: 20%;
    }
  }
}

.c-values--no-rect .c-values__picture::before {
  display: none;
}

.c-values__media {
  margin-bottom: var(--spacer-small);
  position: relative;
  justify-self: stretch;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.c-values__image {
  height: auto;
  max-width: 237px;
  width: 100%;

  @include bp(medium) {
    max-width: 298px;
  }
}

.c-values__text {
  justify-self: end;
}

/* stylelint-disable */
%input-text {
  border-radius: 0;
  padding: 17px 20px;
  border: 1px solid $color-black;
  font-family: $font-sans-serif;
  font-size: 19px;
  line-height: 22px;
  width: 100%;
  color: $color-black;
  background-color: $color-light-grey;

  &:focus,
  &:focus-visible {
    outline-color: $color-orange;
  }

  @include bp-down(small) {
    font-size: 16px;
  }
}
.c-checkout {
  padding-bottom: var(--spacer-sections);

  @include bp(large) {
    padding-top: var(--spacer-small);
  }

  .input-text {
    @extend %input-text;
  }

  .woocommerce-invalid .input-text {
    border: 1px solid $color-orange;
  }

  textarea.input-text {
    max-width: 100%;
    min-width: 100%;
  }

  .select2-container {
    display: block;
  }

  .select2-container--default .select2-selection--single {
    border-radius: 0;
    border-color: $color-black;
    background-color: $color-light-grey;
    height: auto;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $color-black;
    font-family: $font-sans-serif;
    font-size: 19px;
    line-height: 22px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 17px 20px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none;
    width: 13px;
    height: 8px;
    margin-top: -4px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAxIDYuODE0IDYgNy4xMjQtNiIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .woocommerce-input-wrapper {
    display: block;
  }
}

.c-checkout__grid {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;

  @include bp(medium) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.c-checkout__steps {
  @include bp(medium) {
    grid-column: span 6;
    order: 1;
  }

  @include bp-down(medium) {
    order: 2;
  }
}

.c-checkout__basket {
  @include bp-down(medium) {
    order: 1;
    margin: 0 calc(-1 * var(--wrapper-hspace));
    padding-left: var(--wrapper-hspace);
    padding-right: var(--wrapper-hspace);
    background-color: $color-purple;
  }

  @include bp(medium) {
    grid-column: span 6;
    order: 2;
  }

  .product-quantity {
    display: none;
  }
}

.c-checkout__basket-btn {
  @include bp(medium) {
    display: none;
  }

  @include bp-down(medium) {
    position: relative;
    display: block;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 0;
    width: 100%;
    text-align: left;
    color: $color-black;
    background: $color-purple;

    // &::before {
    //   position: absolute;
    //   top: 50%;
    //   right: 0;
    //   transform: translateY(-50%);
    //   content: '';
    //   display: block;
    //   width: 16px;
    //   height: 9px;
    //   background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAxIDYuODE0IDYgNy4xMjQtNiIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=');
    //   transition: $global-transition;

    //   .is-visible & {
    //     transform: translateY(-50%) rotate(180deg);
    //   }
    // }
  }
}

.c-checkout__basket-inner {
  // @include bp-down(medium) {
  //   max-height: 0;
  //   overflow: hidden;
  //   will-change: max-height;
  //   transition: $global-transition;
  //   opacity: 0;
  // }
}

.is-visible .c-checkout__basket-inner {
  opacity: 1;
}

.c-checkout__title {
  padding-bottom: 20px;
  border-bottom: 2px solid $color-black;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  font-family: $font-sans-serif;

  strong {
    color: $color-orange;
  }

  @include bp-down(medium) {
    .c-checkout__basket & {
      display: none;
    }
  }
}

.c-checkout__fields {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include bp(medium) {
    gap: 30px;
  }

  .form-row-first,
  .form-row-last,
  .form-row-wide {
    margin-bottom: 0;
  }

  .form-row-first,
  .form-row-last {
    flex: 1 1 100%;

    @include bp(small) {
      flex: 1 1 calc(50% - 30px);
    }
  }

  .form-row-wide {
    flex: 1 1 100%;
  }
}

tr.recurring-totals,
.woocommerce-account-fields + .c-checkout__title,
.c-checkout__fields + .c-checkout__title {
  margin-top: 30px;

  @include bp(large) {
    margin-top: 60px;
  }
}

.c-checkout__mailerlite {
  font-size: 14px;
  line-height: 1.3;

  @include bp(medium) {
    margin: -15px 0;
  }
}

.c-checkout__small-info {
  display: block;
  font-size: 14px;
  line-height: 1;

  @include bp(medium) {
    margin: -15px 0;
  }
}

.c-checkout__name {
  color: $color-black;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;

  @include bp(large) {
    font-size: 22px;
  }
}

.c-checkout__img {
  flex: 0 0 120px;
  height: 165px;
  overflow: hidden;

  @include bp(large) {
    flex: 0 0 210px;
    height: 287px;
  }

  .attachment-woocommerce_thumbnail {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border: 1px solid $color-black;
  }
}

.c-checkout__cart-item {
  position: relative;
  width: 100%;
  gap: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $color-black;
  display: flex;

  @include bp(large) {
    gap: 30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.c-checkout__cart-item-content {
  justify-content: space-between;
}

tr.cart_item:last-child .c-checkout__cart-item {
  border-bottom: 0;
}

.woocommerce-checkout-review-order-table {
  &, tbody, tfoot,
  .cart_item,
  .c-checkout__cart-item-content,
  .c-checkout__cart-item-details {
    display: flex;
    flex-direction: column;
  }

  tfoot tr {
    display: flex;
    justify-content: space-between;
  }

  tfoot tr:not([class]) td {
    width: 100%;
  }

  .woocommerce-remove-coupon {
    color: $color-black;
    font-size: 80%;
  }

  @include bp-down(medium) {
    margin-top: var(--spacer-small);
    padding-bottom: var(--spacer-small);
  }
}

.c-checkout__product-remove {
  justify-self: end;

  .remove {
    padding: 10px;
    margin: 0 0 -10px -10px;
    display: inline-block;
    font-size: 16px;
    line-height: 1.4;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @include bp(medium) {
      color: #b0b0b0;
    }

    @include bp-down(medium) {
      color: $color-black;
    }
  }
}

.c-checkout__payment .c-btn {
  width: 100%;
}

.c-checkout__payment .button.alt {
  @extend %c-btn;
  @extend %c-btn--secondary;

  width: 100%;
  margin-top: var(--spacer-small);
}

.c-checkout--coupon {
  width: 100%;

  @include bp(medium) {
    max-width: 415px;
  }
}

.c-checkout--coupon-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  .input-text {
    border-radius: 0;
    font-size: 16px;
    line-height: 20px;
    padding: 9px 10px;

    @include bp-down(medium) {
      background-color: $color-purple;
    }
  }

  .c-btn {
    border-radius: 0;
    flex-shrink: 0;
    text-transform: none;
    font-weight: 400;
    padding-top: 9px;
    padding-bottom: 9px;

    @include bp-down(medium) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.cart-subtotal {
  border-top: 2px solid $color-black;
  margin-top: 30px;
  padding-top: 30px;
}

.cart-discount {
  color: $color-orange;
}

.woocommerce-terms-and-conditions-wrapper {
  font-size: 18px;
  line-height: 1.2;

  > * {
    margin-bottom: var(--spacer-small);
  }
}

.c-checkout__payment .is-visible .c-accordion__content {
  max-height: 100% !important;
}

.c-checkout form .form-row label[for=billing_nip] {
  display: none;
}
/* stylelint-enable */

/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: var(--wrapper-width);
  padding-left: var(--wrapper-hspace);
  padding-right: var(--wrapper-hspace);
  position: relative;

  @include bp-down(small) {
    width: 100%;
  }
}

.o-page__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.o-page__inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.o-main {
  align-self: stretch;
  flex-grow: 1;

  @include bp(medium) {
    display: flex;
    flex-direction: column;

    > .o-wrapper {
      flex-grow: 1;
    }

    > .c-instabar {
      justify-self: flex-end;
    }
  }
}

/* stylelint-disable */
body:not(.page-template-template-landing, .page-template-template-maintenance) .o-main > *:first-child:not(.c-text-columns--linktree) {
  padding-top: var(--header-height);
}

body.page-template-template-landing .o-main:has(.c-post__password-form) > *:first-child:not(.c-text-columns--linktree) {
  padding-top: var(--header-height);
}
/* stylelint-enable */

.c-header {
  grid-area: header;
}

.o-wrapper--narrow {
  max-width: 1154px;
  width: 100%;
}

/* stylelint-disable */
#cookie-law-info-bar {
  padding: calc(var(--spacer-medium) /2);
  text-align: left;

  a[role="button"] {
    cursor: pointer;
    text-decoration: underline;
  }
}
/* stylelint-enable */

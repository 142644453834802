/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */

/**
 * The list-inline object simply displays a list of items in one line.
 */

.o-list-emoticons {
  list-style: none;
  margin-left: 0;
}

.o-list-emoticons li {
  position: relative;
  margin-bottom: 0;
}

.woocommerce .woocommerce-result-count {
  @extend %heading-label;
}

.woocommerce .woocommerce-ordering {
  position: relative;
  z-index: 1;
}

.woocommerce select {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  padding-top: calc(var(--spacer-xsmall) - 1px);
  padding-bottom: calc(var(--spacer-xsmall) - 1px);
  padding-right: 30px;
  padding-left: 15px;
  background-color: $color-light-grey;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAxIDYuODE0IDYgNy4xMjQtNiIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPgo=');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include bp-down(small) {
    padding-left: 15px;
  }
}

.woocommerce select,
.woocommerce input[type='number'] {
  @extend %heading-label;

  border-radius: 0;
  font-size: 16px;
  line-height: 1.25;
  appearance: none;
  outline: none;
  border: 1px solid $color-black;
  color: $color-black;

  @include bp-down(small) {
    font-size: 12px;
    letter-spacing: 0;
  }
}

.woocommerce input[type='number'] {
  margin: var(--spacer-xsmall) 0;
}

.woocommerce-notices-wrapper {
  margin: var(--spacer-small) 0;
  font-size: 18px;
  line-height: 1.2;

  li:last-child {
    margin-bottom: 0;
  }
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  font-size: 18px;
  line-height: 1.2;
  background-color: transparent;
}

.woocommerce-error.woocommerce-error {
  border-top-color: $color-orange;

  &::before {
    color: $color-orange;
  }
}

.woocommerce-info.woocommerce-info {
  border-top-color: $color-purple;

  &::before {
    color: $color-purple;
  }
}

/* stylelint-disable */
.xoo-wsc-ft-buttons-cont > .button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
  @extend %c-btn;
  @extend %c-btn--primary;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  @extend %c-btn--secondary;
}

.woocommerce #respond input#submit.alt.disabled,
.woocommerce #respond input#submit.alt.disabled:hover,
.woocommerce #respond input#submit.alt:disabled,
.woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce #respond input#submit.alt:disabled[disabled],
.woocommerce #respond input#submit.alt:disabled[disabled]:hover,
.woocommerce a.button.alt.disabled,
.woocommerce a.button.alt.disabled:hover,
.woocommerce a.button.alt:disabled,
.woocommerce a.button.alt:disabled:hover,
.woocommerce a.button.alt:disabled[disabled],
.woocommerce a.button.alt:disabled[disabled]:hover,
.woocommerce button.button.disabled,
.woocommerce button.button.alt.disabled,
.woocommerce button.button.alt.disabled:hover,
.woocommerce button.button.alt:disabled,
.woocommerce button.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled[disabled],
.woocommerce button.button.alt:disabled[disabled]:hover,
.woocommerce input.button.alt.disabled,
.woocommerce input.button.alt.disabled:hover,
.woocommerce input.button.alt:disabled,
.woocommerce input.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled[disabled],
.woocommerce input.button.alt:disabled[disabled]:hover {
  color: $color-white;
  border-color: $color-orange;
  background-color: $color-orange;
  opacity: 0.5;

  @include bp(medium) {
    padding: calc(var(--spacer-small) - 1px) 25px;
  }

  @include bp-down(medium) {
    padding: 14px var(--spacer-small);
  }
}

.woosb-title-inner,
.woocommerce-grouped-product-list-item__label {
  padding: var(--spacer-xsmall);
  font-size: 90%;
}

#add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
  border-radius: 0;
  background-color: transparent;
}

#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
  padding: var(--spacer-small) var(--spacer-xsmall);
}

#payment ul.wc_payment_methods.wc_payment_methods li.wc_payment_method .tpay-amPmCheckbox input[type="checkbox"] + label {
  font-family: $font-sans-serif;
  font-size: 16px;
  line-height: 1.2;
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
  background-color: $color-white;
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
  border-bottom-color: $color-white;
}

.tpay-insidebg {
  max-width: none;
}

@include bp-down(380px) {
  #wpadminbar #wp-admin-bar-customize>.ab-item, #wpadminbar #wp-admin-bar-edit>.ab-item, #wpadminbar #wp-admin-bar-my-account>.ab-item, #wpadminbar #wp-admin-bar-my-sites>.ab-item, #wpadminbar #wp-admin-bar-site-editor>.ab-item, #wpadminbar #wp-admin-bar-site-name>.ab-item {
    width: 44px;
  }
}
@include bp-down(small) {
  .before-shop-loop {
    display: flex;
    flex-direction: column;
  }
}

.woocommerce .return-to-shop {
  text-align: center;
}

.woocommerce-product-details__short-description ol {
  margin-left: 1.5em;
}

.edit-account .input-text,
.woocommerce-form .input-text,
.lost_reset_password .input-text {
  @extend %input-text;
}

.woocommerce-form.login .woocommerce-form-row + .form-row:not(.form-row-wide) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;

  .woocommerce-form__label-for-checkbox {
    font-size: 90%;
    margin-bottom: var(--spacer-xsmall);
  }
}

.woocommerce-form .woocommerce-LostPassword {
  text-align: center;
  font-size: 90%;
}

.edit-account label {
  font-size: 90%;
}

.edit-account * + fieldset {
  margin-top: var(--spacer-medium);
}
.edit-account fieldset legend {
  @extend %heading-label;
}

.order-actions a.woocommerce-button.button.pay {
  color: $color-black;
  background-color: $color-green;
}

#add_payment_method #payment ul.payment_methods, .woocommerce-cart #payment ul.payment_methods, .woocommerce-checkout #payment ul.payment_methods {
  padding: 0;
}
/* stylelint-enable */

.c-searchbar {
  @include bp(medium) {
    width: 200px;
  }

  @include bp(1400px) {
    width: 234px;
  }
}

@include bp-down(medium) {
  html:not(.xoo-wsc-cart-active) {
    .c-header:focus-within {
      z-index: 1000000;
    }
  }
}

.c-searchbar__form {
  display: flex;
  align-items: center;
  transition: $global-transition;
  transform-origin: top center;

  @include bp-down(medium) {
    position: absolute;
    padding: 0 10px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.c-searchbar__input {
  width: 70px;
  padding: 0;
  border: none;
  border-bottom: 1px solid transparent;
  box-shadow: none !important;
  font-family: $font-sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $color-black;
  background-color: transparent;
  flex: 0;
  transition: $global-transition;

  @include bp-down(medium) {
    width: 30px;
    color: transparent;
  }

  &:focus,
  &:focus-within {
    flex: 1;
    border-bottom: 1px solid $color-black;
    outline: 0;
  }
}

.c-searchbar__form:focus-within {
  @include bp-down(medium) {
    width: 100%;
    background-color: $color-black;
    padding: 45px 25px;
    z-index: 99999;

    .c-searchbar__input {
      width: 100%;
      border-bottom: 1px solid $color-white;
      color: $color-white;
      flex: 1;
    }
  }
}

.c-searchbar__input::placeholder {
  opacity: 1;
  letter-spacing: 1px;
}

.c-searchbar__btn {
  font-size: 28px;
  color: $color-black;
  background-color: transparent;
  flex: 0;
  cursor: pointer;

  @include bp(medium) {
    margin-left: 8px;
  }

  @include bp-down(medium) {
    pointer-events: none;
    position: absolute;

    .c-searchbar__form:focus-within & {
      position: relative;
      margin-left: 8px;
      color: $color-white;
      pointer-events: all;
    }
  }
}
